import {
  mdiBus,
  mdiCircleSlice8,
  mdiRoutes,
  mdiTelevision,
  mdiCog,
  mdiTextBoxMultipleOutline,
  mdiTextBoxSearch,
  mdiChartBar,
  mdiMathLog,
} from "@mdi/js"

export default [
  {
    subheader: "NEXT STOP",
  },
  {
    title: "Stops",
    icon: mdiCircleSlice8,
    to: "next-stop-stops",
  },
  {
    title: "Vehicles",
    icon: mdiBus,
    to: "next-stop-vehicles",
  },
  {
    title: "Routes",
    icon: mdiRoutes,
    to: "next-stop-routes",
  },
  {
    title: "Devices",
    icon: mdiTelevision,
    to: "next-stop-devices",
  },
  {
    title: "Notes",
    icon: mdiTextBoxMultipleOutline,
    to: "next-stop-notes",
  },
  {
    title: "Settings",
    icon: mdiCog,
    to: "next-stop-settings",
  },
  {
    subheader: "ADMINISTRATION",
  },
  {
    title: "Documentation",
    icon: mdiTextBoxSearch,
    to: "administration-documentation",
  },
  {
    title: "Reports",
    icon: mdiChartBar,
    to: "administration-reports",
  },
  {
    title: "Audit Log",
    icon: mdiMathLog,
    to: "administration-audit-log",
  },
]
